<template>
  <div class="col-12 col-xl-6">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>
          {{
            isEdit ? $t("platform.edit_platform") : $t("platform.create_new")
          }}
        </h1>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("platform.create.name") }}
          </div>
          <div class="form-item__field">
            <input
              ref="name"
              v-model="platform.name"
              type="text"
              :class="{ invalid: $v.platform.name.$error }"
            />
            <small
              v-if="
                $v.platform.name.$error &&
                (!$v.platform.name.minLength || platform.name === '')
              "
              class="error"
            >
              {{ $t("min_len_pass", { number: "3" }) }}
            </small>
            <small
              v-if="$v.platform.name.$error && !$v.platform.name.maxLength"
              class="error"
            >
              {{ $t("max_len_pass", { number: 52 }) }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("platform.create.url") }}
          </div>
          <div class="form-item__field">
            <input
              ref="url"
              :disabled="isEdit"
              v-model="platform.url"
              :class="{ invalid: $v.platform.url.$error }"
              type="text"
              placeholder="https://"
            />
            <small
              v-if="
                $v.platform.url.$error &&
                (!$v.platform.url.minLength || platform.url === '')
              "
              class="error"
            >
              {{ $t("min_len_pass", { number: "3" }) }}
            </small>
            <small
              v-if="$v.platform.url.$error && !$v.platform.url.maxLength"
              class="error"
            >
              {{ $t("max_len_pass", { number: 126 }) }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label">
            {{ $t("category") }}
          </div>
          <div class="form-item__field">
            <multiselect
              :disabled="isEdit"
              label="title"
              v-model="platform.category_id"
              :options="categories"
              :searchable="false"
              placeholder=""
              :show-labels="false"
              :allowEmpty="false"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label info-popup">
            {{ $t("platform.postback") }}
            <span v-tooltip="$t('platform.postback_pop_up')">
              <svg v-svg symbol="info" size="0 0 17 17" role="info" />
            </span>
          </div>
          <div class="form-item__field _overflow">
            <input
              ref="postback_url"
              :class="{ invalid: $v.platform.postback_url.$error }"
              type="text"
              v-model="platform.postback_url"
            />
            <small v-if="$v.platform.postback_url.$error" class="error">
              {{ $t("platform.postback_url_val") }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="sep mb-2"></div>
    <div class="row flex-row-reverse justify-content-end align-center">
      <div class="col-12 col-md-auto pt-1">
        <label class="checkbox-switch">
          <input type="checkbox" v-model="platform.adult" checked />
          <span class="checkbox-switch__visible"></span>
          <span class="checkbox-switch__text">{{
            $t("platform.site.show_for_adult")
          }}</span>
        </label>
      </div>
      <div class="col-12 col-md-auto">
        <div class="form-item pb-0">
          <div>
            <button @click="createSite" class="btn _mob100">
              {{ $t("platform.save_platform") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <notifications group="error" />
  </div>
</template>
<script>
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, url } from "vuelidate/lib/validators";

export default {
  name: "SitePlatform",
  mixins: [validationMixin],
  props: {
    createPlatform: {
      type: Function,
      default: function () {},
    },
    editPlatform: {
      type: Function,
      default: function () {},
    },
    editedPlatform: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platform: {
        name: "",
        url: "",
        postback_url: "",
        category_id: { title: "Adult", value: 1 },
        adult: true,
      },
      categories: [
        { title: "Adult", value: 1 },
        { title: "Mainstream", value: 2 },
      ],
    };
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      url: {
        url,
        required,
        minLength: minLength(3),
        maxLength: maxLength(126),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
    },
  },
  mounted() {
    if (this.editedPlatform) {
      this.platform = {
        name: this.editedPlatform.name,
        url: this.editedPlatform.url,
        postback_url: this.editedPlatform.postback_url,
        category_id: this.categories.find(
          (el) => (el.value = this.editedPlatform.category_id)
        ),
        adult: this.editedPlatform.adult,
      };
    }
  },
  methods: {
    createSite() {
      this.$v.$touch();
      if (this.isEdit) {
        let payload = {
          name: this.platform.name,
          adult: +this.platform.adult,
        };
        if (!this.$v.$invalid) {
          this.$emit("editPlatform", payload);
          this.$v.$reset();
        }
      } else {
        let formData = new FormData();
        formData.append("name", this.platform.name);
        formData.append("url", this.platform.url);
        formData.append("category_id", this.platform.category_id.value);
        formData.append("postback_url", this.platform.postback_url);
        formData.append("adult", +this.platform.adult);
        if (!this.$v.$invalid) {
          this.$emit("createPlatform", formData);
          this.$router.push("/platforms");
        }
      }
    },
  },
};
</script>