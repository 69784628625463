<template>
  <form class="row">
    <div class="col-12 col-xl-6">
      <div class="title-row row justify-content-between">
        <div class="col-12 col-md-auto">
          <h1>{{ $t("platform.create_new") }}</h1>
        </div>
      </div>
      <div class="sep _h10"></div>
      <div class="row">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label">
              {{ $t("platform.arbitrage.name") }}
            </div>
            <div class="form-item__field">
              <input
                ref="name"
                v-model="platform.name"
                type="text"
                :class="{ invalid: $v.platform.name.$error }"
              />
              <small
                v-if="
                  $v.platform.name.$error &&
                  (!$v.platform.name.minLength || platform.name === '')
                "
                class="error"
                >{{ $t("min_len_pass", { number: "3" }) }}</small
              >
              <small
                v-if="$v.platform.name.$error && !$v.platform.name.maxLength"
                class="error"
                >{{ $t("max_len_pass", { number: 52 }) }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <label>{{$t('platform.payment_model')}}</label>
       <multiselect
                  label="title"
                  v-model="paymentValue"
                  :options="paymentModel"
                  :searchable="false"
                  placeholder=""
                  :show-labels="false"
                  :allowEmpty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span>{{ option.title }}</span>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <span>{{ option.title }}</span>
                    <small class="hint" v-if="option.title === 'Revshare'">
                     {{$t('platform.revshare_option')}} 
                    </small>
                    <small class="hint" v-if="option.title === 'CPL'">
                      {{$t('platform.cpl_option')}}
                    </small>
                  </template>
                </multiselect>
      <div class="row mt-4">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label">
              <label class="checkbox">
                <input type="checkbox" v-model="platform.accept_tb" checked />
                <span class="checkbox__visible"></span>
                <span class="checkbox__text has-link">{{
                  $t("platform.arbitrage.accept")
                }}</span>
              </label>
            </div>
            <div class="form-item__field">
              <input
                type="text"
                v-model="platform.accept_tb_url"
                ref="accept_tb_url"
                :class="{ invalid: $v.platform.accept_tb_url.$error }"
              />
              <small v-if="$v.platform.accept_tb_url.$error" class="error">
                {{ $t("max_len_pass", { number: 356 }) }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label">
              <label class="checkbox">
                <input type="checkbox" v-model="platform.reject_tb" checked />
                <span class="checkbox__visible"></span>
                <span class="checkbox__text has-link">{{
                  $t("platform.arbitrage.reject")
                }}</span>
              </label>
            </div>
            <div class="form-item__field">
              <input
                type="text"
                v-model="platform.reject_tb_url"
                ref="reject_tb_url"
                :class="{ invalid: $v.platform.reject_tb_url.$error }"
              />
              <small v-if="$v.platform.reject_tb_url.$error" class="error">
                {{ $t("max_len_pass", { number: 356 }) }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-item">
            <div class="form-item__label info-popup">
              {{ $t("platform.postback") }}
              <span v-tooltip="$t('platform.postback_pop_up')">
                <svg v-svg symbol="info" size="0 0 17 17" role="info" />
              </span>
            </div>
            <div class="form-item__field _overflow">
              <input
                type="text"
                v-model="platform.postback_url"
                :class="{ invalid: $v.platform.postback_url.$error }"
              />
              <small v-if="$v.platform.postback_url.$error" class="error">
                {{ $t("platform.postback_url_val") }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="form-item">
            <div class="form-item__label info-popup">
              {{ $t("platform.arbitrage.difficult") }}
              <span v-tooltip="$t('platform.difficult_pop_up')">
                <svg v-svg symbol="info" size="0 0 17 17" role="info" />
              </span>
            </div>
            <div class="form-item__field">
              <multiselect
                v-model="platform.complexity"
                :options="[0, 1, 2, 3, 4, 5]"
                :searchable="false"
                :show-labels="false"
                :allowEmpty="false"
              >
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 align-self-end pb-2">
          <div class="form-item">
            <div class="form-item__field">
              <label class="checkbox">
                <input type="checkbox" v-model="platform.fullscreen" />
                <span class="checkbox__visible"></span>
                <span class="checkbox__text">{{
                  $t("platform.arbitrage.fullsize_mode")
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="sep mb-2"></div>
      <div class="row flex-row-reverse justify-content-end">
        <!-- TODO: monetization -->
        <!-- <div class="col-12 col-md-auto align-self-end pb-2">
          <div class="form-item pb-0">
            <div class="form-item__field">
              <label class="checkbox-switch">
                <input type="checkbox" v-model="platform.monetization" checked />
                <span class="checkbox-switch__visible"></span>
                <span class="checkbox-switch__text info-popup">
                  {{ $t("platform.arbitrage.monetization") }}
                  <button v-tooltip="'Комментарий на пару строк, но можно сделать и больше'">
                    <svg v-svg symbol="info" size="0 0 17 17" role="info" />
                  </button>
                </span>
              </label>
            </div>
          </div>
        </div> -->
        <div class="col-12 col-md-auto">
          <div class="form-item pb-0">
            <div class="form-item__field">
              <button
                type="submit"
                @click.prevent="createArbitrage"
                class="btn _mob100"
              >
                {{ $t("platform.save_platform") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6 d-flex flex-wrap">
      <div class="landings">
        <div class="section" :class="{ invalid: $v.platform.lands.$error }">
          <div class="landings__head">
            <h3>{{ $t("platform.arbitrage.choose_landing") }}</h3>
            <p class="max-390">
              {{ $t("platform.arbitrage.if_more_than_1_landing") }}
            </p>
          </div>
          <div class="landings__list">
            <div
              class="landings__item landing"
              :class="{
                list: order === 'list',
                grid: order === 'grid',
                active: platform.lands.find((id) => id === land.id),
              }"
              v-for="(land, ind) in landings"
              :key="ind"
              @click="addLandingId(land)"
            >
              <div class="landing__thumb thumb">
                <img :src="`${land.image}`" alt="" />
              </div>
              <div class="landing__body">
                <div class="landing__title">
                  {{ $i18n.locale === "ru" ? land.name_ru : land.name_en }}
                </div>
                <div class="landing__footer">
                  <button
                    @click.stop.prevent="openLandModal(land)"
                    class="btn icon-view"
                  >
                    {{ $t("platform.arbitrage.watch") }}
                  </button>
                  <label class="checkbox-landing">
                    <button
                      type="button"
                      @click.stop.prevent="addLandingId(land)"
                    >
                      <svg
                        v-svg
                        symbol="check-land"
                        size="0 0 21 21"
                        role="info"
                      />
                    </button>
                    <span class="checkbox-landing__border"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p class="text-center mb-0 landings__total" v-if="landings">
            {{ $t("platform.arbitrage.active_landing") }}:
            <strong>{{ platform.lands.length }}</strong> {{ $t("from") }}
            {{ landings.length }}
          </p>
          <ul class="landings__view">
            <li>
              <button
                class="btn _icon _view"
                :class="{ active: order === 'grid' }"
                @click.prevent="order = 'grid'"
              >
                <svg v-svg symbol="grid" size="0 0 14 14" role="info" />
              </button>
            </li>
            <li>
              <button
                class="btn _icon _view"
                :class="{ active: order === 'list' }"
                @click.prevent="order = 'list'"
              >
                <svg v-svg symbol="list" size="0 0 12 12" role="info" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <modal
      @closed="clearLandingModal"
      :width="780"
      name="landModal"
      height="auto"
      :scrollable="true"
      classes="creative-modal"
      :shiftY="0"
    >
      <LandingModal :landing="currentLanding" @closeModal="closeLandingModal" />
    </modal>
  </form>
</template>
<script>
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_page-add-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, url } from "vuelidate/lib/validators";
import LandingModal from "@/components/LandingModal";
import LandingMixin from "@/mixins/landModal";

export default {
  name: "ArbitragePlatrofm",
  mixins: [validationMixin, LandingMixin],
  components: { LandingModal },
  props: {
    landings: {
      type: Array,
      default: null,
    },
    editedPlatform: {
      type: Object,
      default: null,
    },
    createPlatform: {
      type: Function,
      default: function () {},
    },
    editPlatform: {
      type: Function,
      default: function () {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platform: {
        complexity: 0,
        lands: [],
        fullscreen: false,
        name: "",
        // TODO: monetization
        // monetization: false,
        accept_tb: false,
        reject_tb: false,
        accept_tb_url: "",
        reject_tb_url: "",
        postback_url: "",
      },
      paymentValue:null,
      paymentModel:[
        {
          title:'CPL',
          value:1,
          $isDisabled: true
        },
        {
          title:'Revshare',
          value:2,
        }
      ],
      order: "grid",
    };
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      accept_tb_url: {
        maxLength: maxLength(356),
      },
      reject_tb_url: {
        maxLength: maxLength(356),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
      lands: {
        minLength: (value) => {
          return value.length >= 1;
        },
      },
    },
  },
  mounted() {
    if (this.isEdit) {
      this.platform = {
        complexity: this.editedPlatform.complexity,
        lands: this.editedPlatform.lands || [],
        fullscreen: this.editedPlatform.fullscreen,
        name: this.editedPlatform.name,
        accept_tb: this.editedPlatform.accept_tb,
        reject_tb: this.editedPlatform.reject_tb,
        accept_tb_url: this.editedPlatform.accept_tb_url,
        reject_tb_url: this.editedPlatform.reject_tb_url,
        postback_url: this.editedPlatform.postback_url,
      };
    }
  },
  methods: {
    addLandingId(el) {
      if (this.platform.lands.find((ind) => ind === el.id)) {
        this.platform.lands = this.platform.lands.filter(
          (ind) => ind !== el.id
        );
      } else {
        this.platform.lands.push(el.id);
      }
    },
    createArbitrage() {
      this.$v.$touch();
      if (this.isEdit) {
        let payload = {
          name: this.platform.name,
          complexity: +this.platform.complexity,
          accept_tb: +this.platform.accept_tb,
          reject_tb: +this.platform.reject_tb,
          accept_tb_url: this.platform.accept_tb_url,
          reject_tb_url: this.platform.reject_tb_url,
          fullscreen: +this.platform.fullscreen,
          lands: this.platform.lands,
          postback_url: this.platform.postback_url,
        };
        if (!this.$v.$invalid) {
          this.$emit("editPlatform", payload);
          this.$v.$reset();
        }
      } else {
        let formData = new FormData();
        formData.append("name", this.platform.name);
        formData.append("complexity", this.platform.complexity);
        formData.append("accept_tb", +this.platform.accept_tb);
        formData.append("reject_tb", +this.platform.reject_tb);
        formData.append("accept_tb_url", this.platform.accept_tb_url);
        formData.append("reject_tb_url", this.platform.reject_tb_url);
        this.platform.lands.forEach((id) => {
          formData.append("lands[]", id);
        });
        formData.append("postback_url", this.platform.postback_url);
        formData.append("fullscreen", +this.platform.fullscreen);
        if (!this.$v.$invalid) {
          this.$emit("createPlatform", formData);
          this.$router.push("/platforms");
        }
      }
    },
  },
};
</script>